<template>
  <div class="mt-3 w-full pr-4 md:pr-0">
    <div class="mt-4">
      <p class="text-sm text-gray-800">Hardware Settings</p>
      <h1 class="text-md mt-2 font-medium">Terminals</h1>
    </div>
    <div class="grid md:grid-cols-4 mt-10 gap-5">
      <div
        @click="gotoTermianals"
        class="flex item-center cursor-pointer justify-between big-shadow p-10 rounded-lg bg-white"
      >
        <img src="@/assets/svg/term.svg" alt="w-6 h-6" />
        <h1 class="text-sm  font-medium ">Register a new terminal</h1>
        <svg
          class="w-4 h-4"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 17L9 9L1 1"
            stroke="#013E9E"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        @click="$router.push({ name: 'TerminalRequest' })"
        class="flex item-center cursor-pointer justify-between big-shadow p-10 rounded-lg bg-white"
      >
        <img src="@/assets/svg/term.svg" alt="w-6 h-6" />
        <h1 class="text-sm  font-medium ">Request a new terminal</h1>
        <svg
          class="w-4 h-4"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 17L9 9L1 1"
            stroke="#013E9E"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>

    <!-- <div class="shadow-md rounded pl-10 pr-10 pt-10 pb-10 ml-12 mr-12 mt-10">
      <img
        src="./../../assets/svg/terminal.svg"
        alt=""
        class="block m-auto mb-8"
      />
      <trac-button class="m-auto block" @button-clicked="gotoTermianals"
        >Terminals</trac-button
      >
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    gotoTermianals() {
      // Goto Terminals
      this.$router.push({ name: "Terminals" });
    },
  },
};
</script>

<style></style>
